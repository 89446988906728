<template lang="pug">
  tr(:class="classes")
    td {{ index + 1 }}
    td {{ property(item.slideMosquitoNet, "name") }}
    td {{ item.isAssembly ? $t("yes") : '-' }}
    td {{ property(item.slideMosquitoNetGlobalAccessoryEntry, "name", "globalAccessory") }}
    td {{ property(item.slideMosquitoNetGlobalNetEntry, "name", "globalNet") }}
    // konstruckja ramy
    td {{ property(item.slideMosquitoNetFrame, "name") }}
    // szyna dolna
    td {{ property(item.slideMosquitoNetBottomRail, "name") }}
    td(v-if="printType === 'full'") {{ formatLength(item.width) }}
    td(v-if="printType === 'full'") {{ formatLength(item.height) }}
    td {{ item.quantity }}
    td {{ item.isBar ? $t("yes") : '-' }}
    td
      p(v-if="!item.slideMosquitoNetAdditionalEntries.length") {{ $t('empty') }}
      p(v-for="(itemAdditional) in item.slideMosquitoNetAdditionalEntries") {{ property(itemAdditional, "name", "slideMosquitoNetAdditional") }}
    // cena zakupu
    td.has-text-right(v-if="priceType !== 'sales' && printType === 'full'") {{ formatPriceValue(formatPrice(item.netPurchaseValue)) }}
    // cena sprzedaży
    td.has-text-right(v-if="priceType !== 'purchase' && printType === 'full'") {{ formatPriceValue(formatPrice(item.netSalesValue)) }}

</template>

<script>
import {mapGetters} from "vuex";
import priceFormatter from "@/helpers/priceFormatter";

export default {
  props: {
    index: Number,
    item: Object,
    priceType: {default: null, required: true},
    printType: {default: null, required: true}
  },
  methods: {
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    remove() {
      return this.$buefy.dialog.confirm({
        message: this.$t("remove_position_confirm"),
        onConfirm: () => {
          this.removeItem(this.index);
        }
      });
    },
    property(object, field, extraField = null) {
      if (extraField) {
        return object && object[extraField][field];
      }
      return object && object[field];
    }
  },
  computed: {
    classes() {
      return {
        "is-editing": this.editIndex === this.index
      };
    },
    ...mapGetters(["hideWholeSalePrice"]),
    ...mapGetters("slideMosquitoNets", ["editIndex"]),
    ...mapGetters("common", [
      "formatLength",
      "formatNumber",
      "formatBoolean",
      "formatPrice"
    ])
  }
};
</script>
