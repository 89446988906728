import router from "./../../router";
import CreateOrder from "./views/CreateOrder";
import store from "../../store";
import slideMosquitoNet from "./store";

store.registerModule("slideMosquitoNets", slideMosquitoNet);
router.addRoutes([
  {
    path: "/slide_mosquito_net/create_order/:copyId?",
    name: "slide_mosquito_net_create_order",
    component: CreateOrder,
    props: true
  }
]);

router.addRoutes([
  {
    path: "/slide_mosquito_net/edit_order/:id",
    name: "slide_mosquito_net_edit_order",
    component: CreateOrder,
    props: true
  }
]);
