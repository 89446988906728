<template lang="pug">
  b-menu.box.is-paddingless.has-border-radius
    b-menu-list(label="Menu")
      b-menu-item(
        v-if="hasPermission('NEWS_READ')"
        icon="newspaper"
        :label="$t('sidebar.news')"
        tag="router-link"
        :to="{ name: 'news_list' }"
      ).main
      b-menu-item(:label="$t('sidebar.order_base')" expanded :disabled="!hasBlockByNews" )
        b-menu-item(
          icon="handshake"
          :label="$t('sidebar.order_base_orders')"
          tag="router-link"
          :to="{ name: 'orders_list' }"
          icon-pack="fas"
          :disabled="!hasBlockByNews"
        )
        b-menu-item(
          v-if="userType === 'company_owner' && companyType === 'company'"
          icon="list"
          :label="$t('sidebar.sub_company_orders')"
          tag="router-link"
          :to="{ name: 'orders_list_subcompanies' }"
          icon-pack="fas"
          :disabled="!hasBlockByNews"
        )
        b-menu-item(
          v-if="userType === 'company_owner' && companyType === 'company'"
          icon="list"
          :label="$t('sidebar.sub_company_valuations')"
          tag="router-link"
          :to="{ name: 'valuations_list_subcompanies' }"
          icon-pack="fas"
          :disabled="!hasBlockByNews"
        )
        b-menu-item(
          icon="dollar-sign"
          :label="$t('sidebar.order_base_valuations')"
          tag="router-link"
          :to="{ name: 'valuations_list' }"
          icon-pack="fas"
          :disabled="!hasBlockByNews"
        )
      //plik utils.scss, ustawienie nth-child(5) dla wyroznienia, 180 linia
      b-menu-item(:label="$t('sidebar.fabric_shades')" :disabled="!hasBlockByNews" v-if="(hasProductType('RTK') && hasPermission('FABRIC_SHADE_READ')) || hasProductType('RDN') && (hasPermission('FABRIC_SHADE_DAY_NIGHT_READ')) ")
        b-menu-item.exposed-menu-item(
          v-if="hasProductType('RTK') && hasPermission('FABRIC_SHADE_READ')"
          icon="plus-circle"
          :label="$t('sidebar.traditional')"
          tag="router-link"
          :to="{ name: 'fabric_shades_create_order' }"
          :disabled="!hasBlockByNews"
        )
        b-menu-item(
          v-if="hasProductType('RDN') && hasPermission('FABRIC_SHADE_DAY_NIGHT_READ')"
          icon="plus-circle"
          :label="$t('sidebar.fabric_shade_day_nights')"
          tag="router-link"
          :to="{ name: 'fabric_shade_day_nights_create_order' }"
          :disabled="!hasBlockByNews"
        )


      b-menu-item(
        icon=""
        v-if="hasProductType('PLI') && hasPermission('PLEAT_READ')"
        :label="$t('sidebar.pleat')"
        tag="router-link"
        :to="{ name: 'pleats_create_order' }"
        :disabled="!hasBlockByNews"
      )

      b-menu-item(
        icon=""
        v-if="hasProductType('RZW') && hasPermission('EXTERNAL_SHUTTER_READ')"
        :label="$t('sidebar.external_shutters')"
        tag="router-link"
        :to="{ name: 'external_shutters_create_order' }"
        :disabled="!hasBlockByNews"
      )
      b-menu-item(:label="$t('sidebar.mosquito')" :disabled="!hasBlockByNews" v-if="(hasProductType('MSQ') && hasPermission('FRAME_MOSQUITO_NET_READ')) || hasProductType('MZW') && (hasPermission('ROLL_MOSQUITO_NET_READ')) ")
        b-menu-item(
          icon=""
          v-if="hasProductType('MSQ') && hasPermission('FRAME_MOSQUITO_NET_READ')"
          :label="$t('sidebar.frame_mosquito_net')"
          tag="router-link"
          :to="{ name: 'frame_mosquito_net_create_order' }"
          :disabled="!hasBlockByNews"
        )
        b-menu-item(
          icon=""
          v-if="hasProductType('MZW') && hasPermission('ROLL_MOSQUITO_NET_READ')"
          :label="$t('sidebar.roll_mosquito_net')"
          tag="router-link"
          :to="{ name: 'roll_mosquito_net_create_order' }"
          :disabled="!hasBlockByNews"
        )
        b-menu-item(
          icon=""
          v-if="hasProductType('PLI') && hasPermission('PLEAT_MOSQUITO_NET_READ')"
          :label="$t('sidebar.pleat_mosquito_net')"
          tag="router-link"
          :to="{ name: 'pleat_mosquito_net_create_order' }"
          :disabled="!hasBlockByNews"
        )
        b-menu-item(
          icon=""
          v-if="hasProductType('MDR') && hasPermission('DOOR_MOSQUITO_NET_READ')"
          :label="$t('sidebar.door_mosquito_net')"
          tag="router-link"
          :to="{ name: 'door_mosquito_net_create_order' }"
          :disabled="!hasBlockByNews"
        )
        b-menu-item(
          icon=""
          v-if="hasProductType('MPR') && hasPermission('SLIDE_MOSQUITO_NET_READ')"
          :label="$t('sidebar.slide_mosquito_net')"
          tag="router-link"
          :to="{ name: 'slide_mosquito_net_create_order' }"
          :disabled="!hasBlockByNews"
        )

      b-menu-item(
        icon=""
        v-if="hasProductType('DET') && hasPermission('DETAIL_READ')"
        :label="$t('sidebar.detail')"
        tag="router-link"
        :to="{ name: 'details_create_order' }"
        :disabled="!hasBlockByNews"
      )

      b-menu-item(:label="$t('sidebar.offer_base')" :disabled="!hasBlockByNews" v-if="hasPermission('OFFER_READ')")
        b-menu-item(
          icon="question"
          :label="$t('sidebar.offer_base_offers')"
          tag="router-link"
          :to="{name: 'offers_list'}"
          icon-pack="fas"
          :disabled="!hasBlockByNews"
        )
        b-menu-item(
          icon="warehouse"
          :label="$t('sidebar.offer_base_offers_templates')"
          tag="router-link"
          :to="{name: 'offer_templates_list'}"
          icon-pack="fas"
          :disabled="!hasBlockByNews"
        )
        b-menu-item(
          icon="plus"
          :label="$t('additional_costs')"
          tag="router-link"
          :to="{name: 'additional_costs_list'}"
          :disabled="!hasBlockByNews"
        )
      //b-menu-item(:label="$t('sidebar.customers_database')")
      b-menu-item(
        v-if="hasPermission('CUSTOMER_READ')"
        :label="$t('sidebar.customers')"
        tag="router-link"
        :to="{ name: 'customers_list' }"
        icon-pack="fas"
        :disabled="!hasBlockByNews"
      )
      b-menu-item(:label="$t('sidebar.management')" :disabled="!hasBlockByNews")
        b-menu-item(
          icon="cogs"
          :label="$t('sidebar.global_settings')"
          tag="router-link"
          :to="{ name: 'management_settings' }"
          :disabled="!hasBlockByNews"
        )
        b-menu-item(
          v-if="hasPermission('COMPANY_PRICE_LIST_ENTRY_READ')"
          icon="list-ul"
          :label="$t('sidebar.pricelists_settings')"
          tag="router-link"
          :to="{ name: 'management_pricelists' }"
          icon-pack="fas"
          :disabled="!hasBlockByNews"

        )
        b-menu-item(
          v-if="userType === 'company_owner' || hasPermission('COMPANY_INSTALLATION_VALUE_READ')"
          icon="dollar-sign"
          :label="$t('sidebar.company_installation_values')"
          tag="router-link"
          :to="{ name: 'company_installation_values_list' }"
          icon-pack="fas"
          :disabled="!hasBlockByNews"
        )
        b-menu-item(
          v-if="hasPermission('FITTER_READ')"
          icon="wrench"
          :label="$t('sidebar.fitters')"
          tag="router-link"
          :to="{ name: 'management_fitters' }"
          :disabled="!hasBlockByNews"
        )
        b-menu-item(
          v-if="userType === 'company_owner' || hasPermission('COMPANY_READ') "
          icon="building"
          :label="$t('sidebar.company')"
          tag="router-link"
          :to="{ name: 'management_company' }"
          icon-pack="fas"
          :disabled="!hasBlockByNews"
        )
        b-menu-item(
          v-if="userType === 'company_owner' && companyType === 'company' && isAllowedToCreateSubCompany"
          icon="building"
          :label="$t('sidebar.sub_companies')"
          tag="router-link"
          :to="{ name: 'sub_companies_list' }"
          icon-pack="fas"
          :disabled="!hasBlockByNews"
        )
        b-menu-item(
          v-if="userType === 'company_owner' && companyType === 'company'"
          icon="building"
          :label="$t('sidebar.workers')"
          tag="router-link"
          :to="{ name: 'company_workers_list' }"
          icon-pack="fas"
          :disabled="!hasBlockByNews"
        )
      b-menu-item(:label="$t('sidebar.support')" :disabled="!hasBlockByNews")
        b-menu-item(
          icon="bug"
          v-if="hasPermission('BUG_REPORT_READ')"
          :label="$t('sidebar.bug_reports')"
          tag="router-link"
          :to="{ name: 'bug_reports_list' }"
          :disabled="!hasBlockByNews"
        )
        b-menu-item(
          icon="flash"
          v-if="hasPermission('ORDER_RECLAMATION_READ')"
          :label="$t('sidebar.order_reclamations')"
          tag="router-link"
          :to="{ name: 'order_reclamations_list' }"
          :disabled="!hasBlockByNews"
        )
        b-menu-item(
          icon="book"
          v-if="hasPermission('SUPPORT_INSTRUCTION_READ')"
          :label="$t('sidebar.instructions')"
          tag="router-link"
          :to="{ name: 'instructions' }"
          :disabled="!hasBlockByNews"
        )
        b-menu-item(
          icon="download"
          v-if="hasPermission('DOWNLOAD_SECTION_READ')"
          :label="$t('sidebar.download_files')"
          tag="router-link"
          :to="{ name: 'downloads' }"
          :disabled="!hasBlockByNews"
        )
</template>
<script>
import {mapGetters} from "vuex";
import router from "../router";

export default {
  computed: {
    ...mapGetters("news", ["list"]),
    ...mapGetters("company", ["hasProductType", "isAllowedToCreateSubCompany", "companyId"]),
    ...mapGetters("auth", ["userType", "companyType", "hasPermission"]),
    /**
     * Check if user has block by news
     * @returns {boolean}
     */
    hasBlockByNews() {
      if (this.list.length === 0) return true;
      return this.list.findIndex(news => (news.priority === "wysoki" || news.priority === "zgoda") && news.newsReads.findIndex(read => {
        return (read.company?.id.toString() === this.companyId || read.subCompany?.id.toString() === this.companyId)
      }) === -1) === -1;
    }
  },
  /**
   * Check if user has block by news
   */
  mounted() {
    if (!this.hasBlockByNews && router.currentRoute.name !== "news_list") {
      router.push({name: "news_list"});
    }
  }
};
</script>
