<template lang="pug">
  div
    .columns.is-desktop
      .column.is-6-desktop
        // Nowa pozycja zamówienia
        app-view-card(:title="$t(itemDataTitle)")
          b-notification(type="is-danger" v-if="globalError" :closable="false") {{ globalError }}
          form
          .columns
            .column
              b-field(grouped)
                app-form-field(:label="$t('version')" field="frameMosquitoNet" horizontal expanded)
                  b-field
                    v-select.w100.has-pointer(
                      :options="frameMosquitoNets"
                      label="name"
                      v-model="item.frameMosquitoNet"
                      :clearable="false"
                      @input="onFrameMosquitoNetChanged"
                      :placeholder="$t('empty')"
                    )
                      template(v-slot:no-options='') {{$t('empty')}}
                    app-form-hover-image(
                      :image="image(item.frameMosquitoNet, 'picture')"
                      @open="openImage($event)"
                    )
              app-form-field(field="isAssembly" horizontal expanded :label="$t('isAssembly')")
                v-select.w100.has-pointer(
                  :options="yesNoOptions"
                  label="text"
                  :disabled="!ifCanBeSentInParts"
                  v-model="item.isAssembly"
                  :reduce="option => option.value"
                  :getOptionLabel="option => option.text"
                  :clearable="false"
                  :placeholder="$t('empty')"
                )
                  template(v-slot:no-options='') {{$t('empty')}}

              app-form-field(:label="$t('accessory_frame_color')" field="frameMosquitoNetGlobalAccessoryEntry" horizontal expanded)
                b-field
                  v-select.w100.has-pointer(
                    :options="itemFrameMosquitoNetGlobalAccessoryEntries"
                    label="name"
                    v-model="item.frameMosquitoNetGlobalAccessoryEntry"
                    :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('globalAccessory')){ return option.globalAccessory['name'] }} return option;}"
                    :clearable="false"
                    @input="onFrameMosquitoNetGlobalAccessoryEntryChanged"
                    :placeholder="$t('empty')"
                  )
                    template(v-slot:no-options='') {{$t('empty')}}
                  app-form-hover-image(
                    :image="image(item.frameMosquitoNetGlobalAccessoryEntry, 'thumbnail', 'globalAccessory')"
                    @open="openImage($event)"
                  )
              app-form-field(field="isBar" horizontal expanded :label="$t('is_bar')")
                v-select.w100.has-pointer(
                  :options="yesNoOptions"
                  :disabled="!ifHasCrossbar"
                  label="text"
                  v-model="item.isBar"
                  :reduce="option => option.value"
                  :getOptionLabel="option => option.text"
                  :clearable="false"
                  @input="onIsBarChanged"
                  :placeholder="$t('empty')"
                )
                  template(v-slot:no-options='') {{$t('empty')}}

              app-form-field(:label="$t('mosquito_net.global_net')" field="frameMosquitoNetGlobalNetEntry" horizontal expanded)
                b-field
                  v-select.w100.has-pointer(
                    :options="itemFrameMosquitoNetGlobalNetEntries"
                    label="name"
                    v-model="item.frameMosquitoNetGlobalNetEntry"
                    :clearable="false"
                    :placeholder="$t('empty')"
                    :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('globalNet')){ return option.globalNet['name'] }} return option;}"
                  )
                    template(v-slot:no-options='') {{$t('empty')}}
                  app-form-hover-image(
                    :image="image(item.frameMosquitoNetGlobalNetEntry, 'thumbnail', 'globalNet')"
                    @open="openImage($event)"
                  )

              app-form-field(:label="$t('mosquito_net.global_clip')" field="frameMosquitoNetGlobalClipEntry" horizontal expanded)
                b-field
                  v-select.w100.has-pointer(
                    :options="itemFrameMosquitoNetGlobalClipEntries"
                    label="name"
                    v-model="item.frameMosquitoNetGlobalClipEntry"
                    :clearable="false"
                    :placeholder="$t('empty')"
                    :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('globalClip')){ return option.globalClip['name'] }} return option;}"
                  )
                    template(v-slot:no-options='') {{$t('empty')}}
                  app-form-hover-image(
                    :image="image(item.frameMosquitoNetGlobalClipEntry, 'thumbnail', 'globalClip')"
                    @open="openImage($event)"
                  )

              app-form-field(:label="$t('mosquito_net.gasket')" field="frameMosquitoNetGasket" horizontal expanded)
                b-field
                  v-select.w100.has-pointer(
                    :options="itemFrameMosquitoNetGaskets"
                    label="name"
                    v-model="item.frameMosquitoNetGasket"
                    :clearable="false"
                    @input="onFrameMosquitoNetGasketChanged"
                    :placeholder="$t('empty')"
                  )
                    template(v-slot:no-options='') {{$t('empty')}}
                  app-form-hover-image(
                    :image="image(item.frameMosquitoNetGasket, 'thumbnail')"
                    @open="openImage($event)"
                  )
              b-field().is-grouped-multiline
                app-form-field(:label="$t('additional')" field="frameMosquitoNetAdditional" horizontal expanded)
                  v-select.has-pointer(
                    :options="asArray(item.frameMosquitoNet, 'frameMosquitoNetAdditionalEntries').filter(entry => entry.isEnabled)"
                    label="name"
                    v-model="item.frameMosquitoNetAdditionalEntries"
                    :clearable="false"
                    :placeholder="$t('empty')"
                    @input="onFrameMosquitoNetAdditionalChanged"
                    :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('frameMosquitoNetAdditional')){ return option.frameMosquitoNetAdditional['name'] }} return option;}"
                    multiple
                  )
                  template(v-slot:no-options='') {{$t('empty')}}
                  div(style="width: 50px; overflow-x:auto;")
                    app-form-hover-image(
                      custom-class="is-4by3"
                      v-for="entry in item.frameMosquitoNetAdditionalEntries"
                      :key="entry['@id']"
                      :image="image(entry, 'picture', 'frameMosquitoNetAdditional')"
                      @open="openImage($event)"
                    )

            .column
              app-form-field(
                :label="$t('order.width', { lengthUnit })"
                field="width"
                :help="widthHelp"
                :showHelp="showHelpForOrderDimensions"
                :errors="errors"
                horizontal
                expanded
              )
                app-form-length(
                  v-model="item.width"
                  :unit="lengthUnit"
                  @blur="onWidthChange"
                  @focus="showHelpForOrderDimensions = true"
                  customClass="pl-1"
                )

              app-form-field(
                :label="$t('order.height', { lengthUnit })"
                field="height"
                :help="heightHelp"
                :showHelp="showHelpForOrderDimensions"
                :errors="errors"
                horizontal
                expanded
              )
                app-form-length(
                  v-model="item.height"
                  :unit="lengthUnit"
                  @focus="showHelpForOrderDimensions = true"
                  @blur="onHeightChange"
                  customClass="pl-1"
                )

              app-form-field(
                :label="$t('order.barHeightFromGround', { lengthUnit })"
                field="barHeightFromGround"
                :help="barHeightFromGroundHelp()"
                :showHelp="showHelpForBarHeightFromGround"
                :errors="errors"
                horizontal
                expanded
              )
                app-form-length(
                  v-model="item.barHeightFromGround"
                  :unit="lengthUnit"
                  @focus="showHelpForBarHeightFromGround = true"
                  :disabled="barHeightFromGroundDisabled"
                  @blur="onBarHeightFromGroundChange"
                  customClass="pl-1"
                )

              app-form-field(:label="$t('order.pieces')" field="quantity" horizontal expanded)
                b-numberinput(v-model="item.quantity" min="1" max="100" @input="onQuantityChange")

              app-form-field(:label="$t('installation_place')" field="shortDescription" horizontal expanded)
                b-input(v-model="item.shortDescription" maxlength="20" :placeholder="$t('installation_place_example')")
              b-field(grouped)
                app-form-field(
                  :label="$t('company_installation_value')"
                  field="companyInstallationValue"
                  horizontal
                  expanded
                )
                  b-field
                    v-select.w100.has-pointer(
                      :options="companyInstallationValuesBySelectedFabricShade"
                      label="name"
                      v-model="item.companyInstallationValue"
                      :clearable="false"
                      :placeholder="$t('empty')"
                    )
                      template(v-slot:no-options='') {{$t('empty')}}
          .columns(v-if="message")
            .column
            .column.is-four-fifths
              .border
                .has-font-size-15.communicate(:class="{'communicate-animation': isMessageBorderAnimating}") {{ message }}
            .column
          table.table.is-fullwidth.is-bordered.is-narrow
            b-loading(:active.sync="priceLoading")
            thead
              tr
                th.has-text-centered.has-border-radius-top(colspan=2) {{ $t('order.total_net_value') }}
              tr
                th(v-if="!hideWholeSalePrice").has-text-centered {{ $t('order.purchase') }}
                th.has-text-centered {{ $t('order.sale') }}
            tbody
              tr
                td(v-if="!hideWholeSalePrice").has-text-right {{ formatPriceValue(formatPrice(item.netPurchaseValue)) }}
                td.has-text-right {{ formatPriceValue(formatPrice(item.netSalesValue)) }}
          b-field
            .buttons
              b-button(
                native-type="submit"
                type="is-primary"
                :loading="submitting"
                @click="saveItem"
                :disabled="cantSave"
              ) {{ $t(addButtonLabel) }}
              //toggle show form
              b-button(type="is-warning" @click="toggleShowForm") {{ $t(`${showForm?'hide':'show'}_form`) }}
              b-button(v-if="isEdit" :loading="submitting" @click.prevent="cancelEdit") {{ $t('cancel') }}
              b-button(type="is-danger" v-if="orderHasAtLeastOneItem" @click.prevent="clearValuation") {{ $t('clear_order') }}
              b-button(@click="calculatePrice") {{ $t('refresh') }}
              //b-button(type="is-primary" @click="automationFormModal = true") {{ $t('automation.title_choose_automation') }}
      .column.is-narrow
        .columns.is-centered
          .column.card-preview.has-min-width-large
            // Podgląd
            .columns
              .column.is-three-quarters-desktop.is-four-fifths-tablet(v-if="!getHideImage")
                app-view-card(:title="pictureTitle" class="has-margin-bottom" paddingless)
                  template(slot="color-picker-selector" v-if="!loading")
                    .columns.is-multiline.has-margin-top-very-small
                      .column
                        b-field(grouped)
                          app-form-field(:label="$t('window_color')" field="window_frame" expanded horizontal)
                            b-field
                              resource-select(
                                :value="windowFrame"
                                resource="window_frames"
                                url="window_frames"
                                extra-props="order[position]=asc"
                                :placeholder="$t('default_frame')"
                                @input="setWindowFrame"
                                nullable
                                expanded
                                full-object
                              )
                              b-button(icon-left="filter-remove" @click.prevent="resetWindowFrame")
                      .column
                        b-field(grouped)
                          app-form-field(:label="$t('wall_color')" field="wall_color" expanded horizontal)
                            b-field
                              input(type="color" v-model="backgroundColor" class="color-picker")

                  div
                    .card-div-preview(:style="{'backgroundColor': backgroundColor}")
                      .frame-mosquito-net(:style="windowFrameBackgroundImage")
                        .net.has-pointer(v-if="item.frameMosquitoNetGlobalNetEntry" @click="openImage(image(item.frameMosquitoNetGlobalNetEntry['globalNet'], 'picture'))"
                          :style="getBackgroundPictureWithContain(item.frameMosquitoNetGlobalNetEntry['globalNet'])")
                          .frame-border-top(:style="getBackgroundPictureWithContain(item.frameMosquitoNetGlobalAccessoryEntry, 'globalAccessory')")
                          .frame-border-left
                            .frame-border-left-inside(:style="getBackgroundPictureWithContain(item.frameMosquitoNetGlobalAccessoryEntry, 'globalAccessory')")
                          .frame-border-right
                            .frame-border-right-inside(:style="getBackgroundPictureWithContain(item.frameMosquitoNetGlobalAccessoryEntry, 'globalAccessory')")
                          .frame-border-bottom(:style="getBackgroundPictureWithContain(item.frameMosquitoNetGlobalAccessoryEntry, 'globalAccessory')")
                          .bar(v-if="item.isBar" :style="getBackgroundPictureWithContain(item.frameMosquitoNetGlobalAccessoryEntry, 'globalAccessory')")



              br
              .column
                .columns.is-multiline.is-mobile
                  .column.is-12-desktop.is-12-tablet
                    .columns
                      .column
                        app-view-card(:title="$t('version')" class="has-margin-bottom")
                          figure
                            .level
                              .level-item
                                img.has-pointer(
                                  v-if="image(item.frameMosquitoNet, 'mechanismPicture')"
                                  :src="image(item.frameMosquitoNet, 'mechanismPicture')"
                                  width="76"
                                  @click="openImage(image(item.frameMosquitoNet, 'mechanismPicture'))"
                                )
                  .column.is-12-desktop.is-12-tablet
                    .columns
                      .column
                        app-view-card(:title="$t('survey')" class="has-margin-bottom")
                          figure
                            .level
                              .level-item
                                img.has-pointer(
                                  v-if="image(item.frameMosquitoNet, 'surveyPicture')"
                                  :src="image(item.frameMosquitoNet, 'surveyPicture')"
                                  width="100"
                                  @click="openImage(image(item.frameMosquitoNet, 'surveyPicture'), item.frameMosquitoNet['surveyDescription'+getLocale])"
                                )
                  .column.is-12-desktop.is-12-tablet
                    .columns
                      .column
                        app-view-card(:title="$t('files')")
                          a(
                            v-if="item.frameMosquitoNet && item.frameMosquitoNet.measurementInstruction"
                            class="button is-primary"
                            :href="item.frameMosquitoNet.measurementInstruction.contentUrl"
                            target="_blank"
                            download
                          ) {{ $t('measurementInstruction') }}
    br
    b-modal(v-model="imageModal")
      .columns(style="width:99%").has-text-centered
        img(:src="imageForModal")
        .columns(v-if="centeredTextForModal")
          .column
          .column.is-half.has-text-centered
            .has-background-white.has-text-black(v-html="centeredTextForModal")
          .column
    //automation-form-modal( productType="frameMosquitoNets" :globalDriveControls="itemGlobalDriveControls()" :automationModalShow="automationFormModal" @close="automationFormModal=false")
</template>

<script>
import http from "../../../../http";
import {mapActions, mapGetters, mapMutations} from "vuex";
import notify from "../../../../helpers/notify";
import simpleClone from "../../../../helpers/simpleClone";
import ResourceSelect from "@/modules/common/components/ResourceSelect";
import prepareItemToSent from "@/helpers/prepareItemToSent";
import objectToCollection from "@/helpers/objectToCollection";
import priceFormatter from "../../../../helpers/priceFormatter";
import AutomationFormModal from "../../../automation/components/AutomationFormModal";

function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
        args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default {
  components: {ResourceSelect, AutomationFormModal},
  data() {
    return {
      globalError: null,
      errors: [],
      submitting: false,
      showWidthError: false,
      priceLoading: false,
      imageModal: false,
      imageForModal: "",
      centeredTextForModal: null,
      hoverImage: null,
      backgroundColor: "#E6E6E6",
      windowFrame: null,
      globalOrderRemoteControlIsPosting: false,
      showHelpForOrderDimensions: false,
      showHelpForBarHeightFromGround: false,
      isCalculated: false,
      automationFormModal: false,
    };
  },
  methods: {
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    ...mapActions("frameMosquitoNets", [
      "setMessage",
      "setIsMessageBorderAnimating",
      "toggleShowForm",
      "saveItem",
      "cancelEdit",
      "saveItemAndClose",
      "updateOrder",
      "updateClearOrder",
      "clearOrder",
    ]),
    ...mapMutations("frameMosquitoNets", ["setItem"]),
    setMessageFromAccessory(message) {
      if (message && message.length > 0) {
        this.setMessage(message);
        this.setIsMessageBorderAnimating(true);
        setTimeout(() => {
          this.setIsMessageBorderAnimating(false);
        }, 1000);
      }
    },
    //nadpisuje eventy keyup i keydown, preventDefault jest defaultowy, oryginalnie zwraca fora, który jest tu wykonywany
    //emit jest customowy
    //up
    // fabricColorHandlers: (map, vm) => ({
    //   ...map,
    //   38: e => {
    //     e.preventDefault();
    //
    //     for (let i = vm.typeAheadPointer - 1; i >= 0; i--) {
    //       if (vm.selectable(vm.filteredOptions[i])) {
    //         vm.typeAheadPointer = i;
    //         break;
    //       }
    //     }
    //     vm.$emit("input", vm.filteredOptions[vm.typeAheadPointer]);
    //   },
    //   //down
    //   40: e => {
    //     e.preventDefault();
    //     for (
    //         let i = vm.typeAheadPointer + 1;
    //         i < vm.filteredOptions.length;
    //         i++
    //     ) {
    //       if (vm.selectable(vm.filteredOptions[i])) {
    //         vm.typeAheadPointer = i;
    //         break;
    //       }
    //     }
    //     vm.$emit("input", vm.filteredOptions[vm.typeAheadPointer]);
    //   }
    // }),
    getPictureFromNestedElement(src, nestedElement) {
      const item = src && src[nestedElement];
      const picture = item && item["picture"];

      return picture || null;
    },
    //zwraca prosty url
    getPictureUrl(src, nestedField = null) {
      let picture = null;

      if (nestedField) {
        picture = this.getPictureFromNestedElement(src, nestedField);
      } else {
        picture = src && src["picture"];
      }

      if (picture) {
        return "url(" + picture.contentUrl + ")";
      }

      return null;
    },
    //zwraca obiekt stylu z tłem i background-size: contain
    getBackgroundPictureWithContain(src, nestedField = null) {
      let picture = null;

      if (nestedField) {
        picture = this.getPictureFromNestedElement(src, nestedField);
      } else {
        picture = src && src["thumbnail"];
      }

      if (picture) {
        return {
          borderImage: "url(" + picture.contentUrl + ") 0 0 0 0 stretch stretch",
          background: "url(" + picture.contentUrl + ") 0% 0% / contain"
        };
      }
      return null;
    },
    getBackgroundPictureWithCover(src, nestedField = null) {
      let picture = null;

      if (nestedField) {
        picture = this.getPictureFromNestedElement(src, nestedField);
      } else {
        picture = src && src["thumbnail"];
      }

      if (picture) {
        return {
          borderImage: "url(" + picture.contentUrl + ") 0 0 0 0 stretch stretch",
          background: "url(" + picture.contentUrl + ") 0% 0% / cover",
        };
      }
      return null;
    },
    //zwraca obiekt stylu z tłem
    getBackgroundPicture(src, nestedField = null) {
      let picture = null;

      if (nestedField) {
        picture = this.getPictureFromNestedElement(src, nestedField);
      } else {
        picture = src && src["picture"];
      }

      if (picture) {
        return {
          background: "url(" + picture.contentUrl + ")"
        };
      }

      return null;
    },
    openImage(image, text = null) {
      this.imageForModal = image;
      if (text) {
        this.centeredTextForModal = text;
      } else {
        this.centeredTextForModal = null;
      }
      this.imageModal = true;
    },
    asArray(object, field) {
      return (object && object[field]) || [];
    },
    onFabricShadeFabricShadeAdditonalEntriesChanged() {
      this.calculatePrice();
    },
    onGlobalDriveChanged(shouldCalculateAndChangeMessage = true) {
      if (shouldCalculateAndChangeMessage) {
        this.setMessageFromAccessory(this.item.globalDrive.message);
        this.calculatePrice();
      }
    },
    onQuantityChange() {
      const side = this.item.driveSide;
      if (side !== "P" || side !== "L" || side !== "L+P") {
        this.item.driveSide = "L";
      }
      this.calculatePrice();
    },
    onFrameMosquitoNetChanged() {
      this.setMessageFromAccessory(this.item.frameMosquitoNet.message);
      if (this.itemFrameMosquitoNetGlobalAccessoryEntries.find(entry => entry["@id"] === this.item.frameMosquitoNetGlobalAccessoryEntry["@id"] &&
          entry.globalAccessory["@id"] === this.item.frameMosquitoNetGlobalAccessoryEntry.globalAccessory["@id"]) === undefined) {
        this.item.frameMosquitoNetGlobalAccessoryEntry = this.itemFrameMosquitoNetGlobalAccessoryEntries[0];
      }
      if (this.itemFrameMosquitoNetGlobalNetEntries.find(entry => entry["@id"] === this.item.frameMosquitoNetGlobalNetEntry["@id"]
          && entry.globalNet["@id"] === this.item.frameMosquitoNetGlobalNetEntry.globalNet["@id"]) === undefined) {
        this.item.frameMosquitoNetGlobalNetEntry = this.itemFrameMosquitoNetGlobalNetEntries[0];
      }
      if (this.itemFrameMosquitoNetGlobalClipEntries.find(entry => entry["@id"] === this.item.frameMosquitoNetGlobalClipEntry["@id"] &&
          entry.globalClip["@id"] === this.item.frameMosquitoNetGlobalClipEntry.globalClip["@id"]) === undefined) {
        this.item.frameMosquitoNetGlobalClipEntry = this.itemFrameMosquitoNetGlobalClipEntries[0];
      }
      if (this.itemFrameMosquitoNetGaskets.find(entry => entry["@id"] === this.item.frameMosquitoNetGasket["@id"]) === undefined) {
        this.item.frameMosquitoNetGasket = this.itemFrameMosquitoNetGaskets[0];
      }

      this.calculatePrice();
    },

    isWidthValid() {
      this.removeError("width");

      if (this.item.width > this.maxWidth) {
        this.addError(
            "width",
            this.$t("max_width_error", {
              width: this.formatLength(this.maxWidth),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      if (this.item.width < this.minWidth) {
        this.addError(
            "width",
            this.$t("min_width_error", {
              width: this.formatLength(this.minWidth),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      // if (this.item.fabricShade.fabricWidthOperator === "nie_mniejsza_niz") {
      //   if (Math.abs(this.item.width - this.item.fabricWidth) < this.item.fabricShade.fabricWidth && this.item.fabricWidth > 0) {
      //     this.addError(
      //         "width",
      //         this.$t("not_smaller_than", {
      //           width: this.formatLength(this.item.fabricShade.fabricWidth),
      //           lengthUnit: this.lengthUnit
      //         })
      //     );
      //     return false;
      //   }
      // }
      //
      // if (this.item.fabricShade.fabricWidthOperator === "mniejsza_o") {
      //   if (Math.abs(this.item.width - this.item.fabricWidth) > this.item.fabricShade.fabricWidth && this.item.fabricWidth > 0) {
      //     this.addError(
      //         "width",
      //         this.$t("smaller_than", {
      //           width: this.formatLength(this.item.fabricShade.fabricWidth),
      //           lengthUnit: this.lengthUnit
      //         })
      //     );
      //     return false;
      //   }
      // }

      return true;
    },

    onFrameMosquitoNetGlobalAccessoryEntryChanged() {
      this.calculatePrice();
    },
    onFrameMosquitoNetAdditionalChanged() {
      this.calculatePrice();
    },
    onFrameMosquitoNetGasketChanged() {
      this.calculatePrice();
    },

    // isGlassWidthValid() {
    //   this.removeError("glassWidth");
    //
    //   if (this.item.width < this.item.glassWidth) {
    //     this.addError(
    //         "glassWidth",
    //         this.$t("max_glass_width_error", {
    //           width: this.formatLength(this.item.width),
    //           lengthUnit: this.lengthUnit
    //         })
    //     );
    //     return false;
    //   }
    //
    //   return true;
    // },
    barHeightFromGroundHelp() {
      return this.$t("bar_height_from_ground_help", {
        lengthUnit: this.lengthUnit
      });
    },
    onWidthChange() {
      this.showHelpForOrderDimensions = false;
      this.isWidthValid()
    },

    isHeightValid() {
      this.removeError("height");

      if (this.item.height > this.maxHeight) {
        this.addError(
            "height",
            this.$t("max_height_error", {
              height: this.formatLength(this.maxHeight),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      if (this.item.height < this.minHeight) {
        this.addError(
            "height",
            this.$t("min_height_error", {
              height: this.formatLength(this.minHeight),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      return true;
    },
    isGlassHeightValid() {
      this.removeError("glassHeight");

      if (this.item.height < this.item.glassHeight) {
        this.addError(
            "glassHeight",
            this.$t("max_glass_height_error", {
              height: this.formatLength(this.item.height),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      return true;
    },
    onHeightChange() {
      this.showHelpForOrderDimensions = false;
      this.isHeightValid();
      this.isBarHeightFromGroundValid();
      // if (this.isWithGlassSizes) this.isGlassHeightValid()
    },
    onBarHeightFromGroundChange() {
      this.showHelpForBarHeightFromGround = false;
      this.isBarHeightFromGroundValid();
    },
    isBarHeightFromGroundValid() {
      this.removeError("barHeightFromGround");

      if (this.barHeightFromGroundDisabled) {
        this.item.barHeightFromGround = 0
        return true;
      }

      if (this.item.barHeightFromGround <= 0) {
        this.addError(
            "barHeightFromGround",
            this.$t("min_bar_height_from_ground_error", {
              height: 0,
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      if (this.item.barHeightFromGround > this.item.height) {
        this.addError(
            "barHeightFromGround",
            this.$t("max_bar_height_from_ground_error", {
              height: this.formatLength(this.item.height),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }
    },
    onIsBarChanged() {
      this.showHelpForBarHeightFromGround = false;
      this.isBarHeightFromGroundValid();
    },
    setWindowFrame(newWindowFrame) {
      this.windowFrame = newWindowFrame;
    },
    resetWindowFrame() {
      this.windowFrame = null;
    },
    // setFabricWidth() {
    //   if (this.item.fabricShade.fabricWidthOperator === "nie_mniejsza_niz") {
    //     if (this.item.width && this.item.width > 0 && (!this.item.fabricWidth || (this.item.fabricWidth && Math.abs(this.item.fabricWidth - this.item.width) < this.item.fabricShade.fabricWidth))) {
    //       this.item.fabricWidth =
    //           this.item.width - this.item.fabricShade.fabricWidth;
    //     }
    //     if (this.item.fabricWidth > this.item.width) {
    //       this.item.fabricWidth = this.item.width - this.item.fabricShade.fabricWidth;
    //     }
    //   }
    //   if (this.item.fabricShade.fabricWidthOperator === "mniejsza_o") {
    //     this.item.fabricWidth = this.item.width - this.item.fabricShade.fabricWidth;
    //   }
    // },
    calculatePrice: debounce(async function () {
      if (
          this.item.width >= this.minWidth &&
          this.item.height >= this.minHeight
      ) {
        this.priceLoading = true;
        let item = simpleClone(this.item);
        item = prepareItemToSent(item);
        delete item["@id"];
        delete item.id;
        await http
            .post(`/calculate/frame_mosquito_net_order_item`, item)
            .then(({data}) => {
              this.item.netPurchaseValue = data.netPurchaseValue;
              this.item.netSalesValue = data.netSalesValue;
              this.isCalculated = true;
            })
            .catch(error => {
              this.isCalculated = false;
              notify(error.response.data["hydra:description"], "danger");
            });
        this.priceLoading = false;
      }
    }, 400),
    addError(field, message) {
      let existsError = this.errors.find(
          ({propertyPath}) => propertyPath === field
      );
      if (existsError) {
        existsError.message = message;
      } else {
        this.errors = this.errors.concat({propertyPath: field, message});
      }
    },
    removeError(field) {
      this.errors = this.errors.filter(
          ({propertyPath}) => field !== propertyPath
      );
    },
    setFirst(value) {
      if (value === "itemFabricShadeGlobalAccessoryEntries") {
        if (this.isEdit && !this.changeStatusesForEditItem.accessory) {
          this.changeStatusesForEditItem.accessory = true;
        } else {
          this.item.fabricShadeGlobalAccessoryEntry =
              this.itemFabricShadeGlobalAccessoryEntries[0] ?? null;
        }
      } else if (value === "itemFabricShadeGlobalBalkEntries") {
        if (this.isEdit && !this.changeStatusesForEditItem.balk) {
          this.changeStatusesForEditItem.balk = true;
        } else {
          this.item.fabricShadeGlobalBalkEntry =
              this.itemFabricShadeGlobalBalkEntries[0] ?? null;
        }
        // } else if (value === "itemFabricShadeGroups") {
        //   if (this.isEdit && !this.changeStatusesForEditItem.group) {
        //     this.changeStatusesForEditItem.group = true;
        //   } else {
        //     this.item.fabricShadeGroup = this.itemFabricShadeGroups[0] ?? null;
        //     this.item.fabric = this.itemFabrics[0] ?? null;
        //     this.item.fabricColor =
        //         this.item.fabric && this.item.fabric.defaultFabricColor
        //             ? this.item.fabric.defaultFabricColor
        //             : null;
        //   }
      } else if (value === "itemFabricColors") {
        if (this.isEdit && !this.changeStatusesForEditItem.fabricColor) {
          this.changeStatusesForEditItem.fabricColor = true;
        } else {
          this.item.fabricColor =
              this.item.fabric && this.item.fabric.defaultFabricColor
                  ? this.item.fabric.defaultFabricColor
                  : null;
        }
      } else if (value === "itemFabrics") {
        if (this.isEdit && !this.changeStatusesForEditItem.fabric) {
          this.changeStatusesForEditItem.fabric = true;
        } else {
          this.item.fabric = this.itemFabrics[0] ?? null;
          this.item.fabricColor =
              this.item.fabric && this.item.fabric.defaultFabricColor
                  ? this.item.fabric.defaultFabricColor
                  : null;
        }
      } else if (value === "itemFabricShadeGlobalRunnerEntries") {
        if (this.isEdit && !this.changeStatusesForEditItem.runner) {
          this.changeStatusesForEditItem.runner = true;
        } else {
          this.item.fabricShadeGlobalRunnerEntry =
              this.itemFabricShadeGlobalRunnerEntries[0] ?? null;
        }
      }
    },
    handleItemWidthChange() {
      this.calculatePrice();
    },
    handleItemHeightChange() {
      this.calculatePrice();
    },
    handleItemGlassHeightChange() {
      this.calculatePrice();
    },
    handleItemGlassWidthChange() {
      this.calculatePrice();
    },

    clearValuation() {
      return this.$buefy.dialog.confirm({
        message: this.$t("remove_valuation_confirm"),
        onConfirm: () => {
          this.clearOrder();
        }
      });
    },
  },
  /**
   * Computed properties
   */
  computed: {
    orderHasAtLeastOneItem() {
      return this.order.frameMosquitoNetOrderItems.length > 0;
    },
    companyInstallationValuesBySelectedFabricShade() {
      if (!this.item.frameMosquitoNet) {
        return [];
      }

      const installationArray = objectToCollection(
          this.companyInstallationValues
      );

      return installationArray.filter(
          civ =>
              civ.frameMosquitoNets &&
              civ.frameMosquitoNets.findIndex(
                  fs => fs === this.item.frameMosquitoNet["@id"]
              ) > -1
      );
    },
    balkBackgroundPictureWithContain() {
      let picture = null;
      const src = this.item.frameMosquitoNetGlobalBalkEntry;
      const nestedField = "globalBalk";
      picture = this.getPictureFromNestedElement(src, nestedField);
      if (picture) {
        return {
          background: "url(" + picture.contentUrl + ") 0% 0% / contain"
        };
      }
      return null;
    },
    getLocale() {
      const local = this.$i18n.locale.toUpperCase();
      if (this.locales.find(locale => local === locale)) {
        return local;
      }
      return "EN";
    },
    windowFrameBackgroundImage() {
      if (this.windowFrame && this.windowFrame.picture) {
        return {
          backgroundImage: "url(" + this.windowFrame.picture.contentUrl + ")"
        };
      }
      return {
        backgroundColor: "white"
      };
    },
    loading() {
      return this.$store.getters["common/loading"];
    },
    itemDataTitle() {
      if (this.isEdit) {
        return "item_edit_data_title";
      }
      return "item_data_title";
    },
    addButtonLabel() {
      if (this.isEdit) {
        return "save";
      }
      return "add";
    },
    cantSave() {
      if (!this.isCalculated) {
        return true;
      } else if (this.item.width === 0 || this.item.height === 0) {
        return true;
      } else if (
          this.item.width > this.maxWidth ||
          this.item.height > this.maxHeight
      ) {
        return true;
      } else if (this.errors.length > 0) {
        return true
      }
      return false;
    },
    getHideImage() {
      return this.hideImage
    },
    ...mapGetters("frameMosquitoNets", [
      "order",
      "showForm",
      "image",
      "frameMosquitoNets",
      "itemFrameMosquitoNet",
      "ifCanBeSentInParts",
      "ifHasCrossbar",
      "itemFrameMosquitoNetGlobalAccessoryEntries",
      "itemFrameMosquitoNetGlobalNetEntries",
      "itemFrameMosquitoNetGlobalClipEntries",
      "itemFrameMosquitoNetGaskets",
      "item",
      "isEdit",
      "message",
      "isMessageBorderAnimating",
      "changeStatusesForEditItem",
      "barHeightFromGroundDisabled"
    ]),
    ...mapGetters(["locales", "hideImage", "hideWholeSalePrice"]),
    ...mapGetters("company", ["lengthUnit", "companyInstallationValues"]),
    ...mapGetters("common", ["formatLength", "formatPrice", "yesNoOptions"]),
    pictureTitle() {
      if (this.item.frameMosquitoNet) {
        return this.$t("picture_review", {
          frameMosquitoNetName: this.item.frameMosquitoNet.name
        });
      }
      return " ";
    },
    widthHelp() {
      const showMax = this.maxWidth !== Number.MAX_SAFE_INTEGER;

      let message = `min: ${this.formatLength(this.minWidth)} ${
          this.lengthUnit
      }`;

      message += showMax
          ? `, max: ${this.formatLength(this.maxWidth)} ${this.lengthUnit}`
          : ``;

      return message;
    },
    heightHelp() {
      const showMax = this.maxHeight !== Number.MAX_SAFE_INTEGER;

      let message = `min: ${this.formatLength(this.minHeight)} ${
          this.lengthUnit
      }`;

      message += showMax
          ? `, max: ${this.formatLength(this.maxHeight)} ${this.lengthUnit}`
          : ``;

      return message;
    },
    minWidth() {
      return this.item.frameMosquitoNet && this.item.frameMosquitoNet.minWidth;
    },
    maxWidth() {
      const maxWidth = this.item.frameMosquitoNet && this.item.frameMosquitoNet.maxWidth;
      if (maxWidth) {
        return maxWidth;
      }
      return Number.MAX_SAFE_INTEGER;
    },
    maxHeight() {
      return this.item.frameMosquitoNet && this.item.frameMosquitoNet.maxHeight;
    },
    minHeight() {
      return this.item.frameMosquitoNet && this.item.frameMosquitoNet.minHeight;
    },

  },
  /**
   * Watchers
   */
  watch: {
    "item.width": "handleItemWidthChange",
    "item.height": "handleItemHeightChange",
    companyInstallationValuesBySelectedFabricShade(values) {
      if (values.length > 0) {
        this.item.companyInstallationValue = values[0];
      }
    },
  }
};
</script>
