<template lang="pug">
  div
    .columns.is-desktop
      .column.is-6-desktop
        // Nowa pozycja zamówienia
        app-view-card(:title="$t(itemDataTitle)")
          b-notification(type="is-danger" v-if="globalError" :closable="false") {{ globalError }}
          form
          .columns
            .column
              b-field(grouped)
                app-form-field(:label="$t('version')" field="slideMosquitoNet" horizontal expanded)
                  b-field
                    v-select.w100.has-pointer(
                      :options="slideMosquitoNets"
                      label="name"
                      v-model="item.slideMosquitoNet"
                      :clearable="false"
                      @input="onSlideMosquitoNetChanged"
                      :placeholder="$t('empty')"
                    )
                      template(v-slot:no-options='') {{$t('empty')}}
                    app-form-hover-image(
                      :image="image(item.slideMosquitoNet, 'picture')"
                      @open="openImage($event)"
                    )
              app-form-field(field="isAssembly" horizontal expanded :label="$t('isAssembly')")
                v-select.w100.has-pointer(
                  :options="yesNoOptions"
                  label="text"
                  :disabled="!ifCanBeSentInParts"
                  v-model="item.isAssembly"
                  :reduce="option => option.value"
                  :getOptionLabel="option => option.text"
                  :clearable="false"
                  :placeholder="$t('empty')"
                )
                  template(v-slot:no-options='') {{$t('empty')}}

              app-form-field(:label="$t('accessory_frame_color')" field="slideMosquitoNetGlobalAccessoryEntry" horizontal expanded)
                b-field
                  v-select.w100.has-pointer(
                    :options="itemSlideMosquitoNetGlobalAccessoryEntries"
                    label="name"
                    v-model="item.slideMosquitoNetGlobalAccessoryEntry"
                    :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('globalAccessory')){ return option.globalAccessory['name'] }} return option;}"
                    :clearable="false"
                    @input="onSlideMosquitoNetGlobalAccessoryEntryChanged"
                    :placeholder="$t('empty')"
                  )
                    template(v-slot:no-options='') {{$t('empty')}}
                  app-form-hover-image(
                    :image="image(item.slideMosquitoNetGlobalAccessoryEntry, 'thumbnail', 'globalAccessory')"
                    @open="openImage($event)"
                  )

              app-form-field(:label="$t('mosquito_net.global_net')" field="slideMosquitoNetGlobalNetEntry" horizontal expanded)
                b-field
                  v-select.w100.has-pointer(
                    :options="itemSlideMosquitoNetGlobalNetEntries"
                    label="name"
                    v-model="item.slideMosquitoNetGlobalNetEntry"
                    :clearable="false"
                    :placeholder="$t('empty')"
                    :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('globalNet')){ return option.globalNet['name'] }} return option;}"
                  )
                    template(v-slot:no-options='') {{$t('empty')}}
                  app-form-hover-image(
                    :image="image(item.slideMosquitoNetGlobalNetEntry, 'thumbnail', 'globalNet')"
                    @open="openImage($event)"
                  )

              app-form-field(:label="$t('mosquito_net.frame')" field="slideMosquitoNetFrame" horizontal expanded)
                b-field
                  v-select.w100.has-pointer(
                    :options="slideMosquitoNetFrames"
                    label="name"
                    v-model="item.slideMosquitoNetFrame"
                    @input="onSlideMosquitoNetFrameChanged"
                    :clearable="false"
                    :placeholder="$t('empty')"
                  )
                    template(v-slot:no-options='') {{$t('empty')}}
                  app-form-hover-image(
                    :image="image(item.slideMosquitoNetFrame, 'thumbnail')"
                    @open="openImage($event)"
                  )

              app-form-field(:label="$t('mosquito_net.bottom_rail')" field="slideMosquitoNetBottomRail" horizontal expanded)
                b-field
                  v-select.w100.has-pointer(
                    :options="slideMosquitoNetBottomRails"
                    label="name"
                    v-model="item.slideMosquitoNetBottomRail"
                    @input="onSlideMosquitoNetBottomRailChanged"
                    :clearable="false"
                    :placeholder="$t('empty')"
                  )
                    template(v-slot:no-options='') {{$t('empty')}}
                  app-form-hover-image(
                    :image="image(item.slideMosquitoNetBottomRail, 'thumbnail')"
                    @open="openImage($event)"
                  )


              b-field(grouped)
                app-form-field(:label="$t('additional')" field="slideMosquitoNetAdditional" horizontal expanded)
                  v-select.has-pointer.w100(
                    :options="asArray(item.slideMosquitoNet, 'slideMosquitoNetAdditionalEntries').filter(entry => entry.isEnabled)"
                    label="name"
                    v-model="item.slideMosquitoNetAdditionalEntries"
                    :clearable="false"
                    :placeholder="$t('empty')"
                    @input="onSlideMosquitoNetAdditionalChanged"
                    :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('slideMosquitoNetAdditional')){ return option.slideMosquitoNetAdditional['name'] }} return option;}"
                    multiple
                  )
                    template(v-slot:no-options='') {{$t('empty')}}

            .column
              app-form-field(
                :label="$t('order.width', { lengthUnit })"
                field="width"
                :help="widthHelp"
                :showHelp="showHelpForOrderDimensions"
                :errors="errors"
                horizontal
                expanded
              )
                app-form-length(
                  v-model="item.width"
                  :unit="lengthUnit"
                  @blur="onWidthChange"
                  @focus="showHelpForOrderDimensions = true"
                  customClass="pl-1"
                )

              app-form-field(
                :label="$t('order.height', { lengthUnit })"
                field="height"
                :help="heightHelp"
                :showHelp="showHelpForOrderDimensions"
                :errors="errors"
                horizontal
                expanded
              )
                app-form-length(
                  v-model="item.height"
                  :unit="lengthUnit"
                  @focus="showHelpForOrderDimensions = true"
                  @blur="onHeightChange"
                  customClass="pl-1"
                )

              app-form-field(field="isBar" horizontal expanded :label="$t('is_bar')")
                v-select.w100.has-pointer(
                  :options="yesNoOptions"
                  :disabled="!ifHasCrossbar"
                  label="text"
                  v-model="item.isBar"
                  :reduce="option => option.value"
                  :getOptionLabel="option => option.text"
                  :clearable="false"
                  @input="onIsBarChanged"
                  :placeholder="$t('empty')"
                )
                  template(v-slot:no-options='') {{$t('empty')}}
              app-form-field(
                :label="$t('order.barHeightFromGround', { lengthUnit })"
                field="barHeightFromGround"
                :help="barHeightFromGroundHelp()"
                :showHelp="showHelpForBarHeightFromGround"
                :errors="errors"
                horizontal
                expanded
              )
                app-form-length(
                  v-model="item.barHeightFromGround"
                  :unit="lengthUnit"
                  @focus="showHelpForBarHeightFromGround = true"
                  :disabled="barHeightFromGroundDisabled"
                  @blur="onBarHeightFromGroundChange"
                  customClass="pl-1"
                )

              app-form-field(:label="$t('order.pieces')" field="quantity" horizontal expanded)
                b-numberinput(v-model="item.quantity" min="1" max="100" @input="onQuantityChange")

              app-form-field(:label="$t('installation_place')" field="shortDescription" horizontal expanded)
                b-input(v-model="item.shortDescription" maxlength="20" :placeholder="$t('installation_place_example')")
              b-field(grouped)
                app-form-field(
                  :label="$t('company_installation_value')"
                  field="companyInstallationValue"
                  horizontal
                  expanded
                )
                  b-field
                    v-select.w100.has-pointer(
                      :options="companyInstallationValuesBySelectedFabricShade"
                      label="name"
                      v-model="item.companyInstallationValue"
                      :clearable="false"
                      :placeholder="$t('empty')"
                    )
                      template(v-slot:no-options='') {{$t('empty')}}
          .columns(v-if="message")
            .column
            .column.is-four-fifths
              .border
                .has-font-size-15.communicate(:class="{'communicate-animation': isMessageBorderAnimating}") {{ message }}
            .column
          table.table.is-fullwidth.is-bordered.is-narrow
            b-loading(:active.sync="priceLoading")
            thead
              tr
                th.has-text-centered.has-border-radius-top(colspan=2) {{ $t('order.total_net_value') }}
              tr
                th(v-if="!hideWholeSalePrice").has-text-centered {{ $t('order.purchase') }}
                th.has-text-centered {{ $t('order.sale') }}
            tbody
              tr
                td(v-if="!hideWholeSalePrice").has-text-right {{ formatPriceValue(formatPrice(item.netPurchaseValue)) }}
                td.has-text-right {{ formatPriceValue(formatPrice(item.netSalesValue)) }}
          b-field
            .buttons
              b-button(
                native-type="submit"
                type="is-primary"
                :loading="submitting"
                @click="saveItem"
                :disabled="cantSave"
              ) {{ $t(addButtonLabel) }}
              //toggle show form
              b-button(type="is-warning" @click="toggleShowForm") {{ $t(`${showForm?'hide':'show'}_form`) }}
              b-button(v-if="isEdit" :loading="submitting" @click.prevent="cancelEdit") {{ $t('cancel') }}
              b-button(type="is-danger" v-if="orderHasAtLeastOneItem" @click.prevent="clearValuation") {{ $t('clear_order') }}
              b-button(@click="calculatePrice") {{ $t('refresh') }}
              //b-button(type="is-primary" @click="automationFormModal = true") {{ $t('automation.title_choose_automation') }}
      .column.is-narrow
        .columns.is-centered
          .column.card-preview.has-min-width-large
            // Podgląd
            .columns
              .column.is-three-quarters-desktop.is-four-fifths-tablet(v-if="!getHideImage")
                app-view-card(:title="pictureTitle" class="has-margin-bottom" paddingless)
                  template(slot="color-picker-selector" v-if="!loading")
                    .columns.is-multiline.has-margin-top-very-small
                      .column
                        b-field(grouped)
                          app-form-field(:label="$t('window_color')" field="window_frame" expanded horizontal)
                            b-field
                              resource-select(
                                disabled
                                :value="windowFrame"
                                resource="window_frames"
                                url="window_frames"
                                extra-props="order[position]=asc"
                                :placeholder="$t('default_frame')"
                                @input="setWindowSlide"
                                nullable
                                expanded
                                full-object
                              )
                              b-button(icon-left="filter-remove" @click.prevent="resetWindowSlide")
                      .column
                        b-field(grouped)
                          app-form-field(:label="$t('wall_color')" field="wall_color" expanded horizontal)
                            b-field
                              input(type="color" v-model="backgroundColor" class="color-picker")

                  div
                    .card-div-preview(:style="{'backgroundColor': backgroundColor}")
                      .slide-mosquito-net(:style="windowSlideBackgroundImage")
                        .net-slide.has-pointer(v-if="item.slideMosquitoNetGlobalNetEntry" @click="openImage(image(item.slideMosquitoNetGlobalNetEntry['globalNet'], 'picture'))"
                          :style="getBackgroundPictureWithContain(item.slideMosquitoNetGlobalNetEntry['globalNet'])")
                          .window-inside(:style="{'backgroundColor': backgroundColor}")
                          .slide-border-top(:style="getBackgroundPictureWithContain(item.slideMosquitoNetGlobalAccessoryEntry, 'globalAccessory')")
                          .slide-border-top-inside(:style="getBackgroundPictureWithContain(item.slideMosquitoNetGlobalAccessoryEntry, 'globalAccessory')")
                          .slide-border-left
                            .slide-border-left-inside(:style="getBackgroundPictureWithContain(item.slideMosquitoNetGlobalAccessoryEntry, 'globalAccessory')")
                          .slide-border-right
                            .slide-border-right-inside(:style="getBackgroundPictureWithContain(item.slideMosquitoNetGlobalAccessoryEntry, 'globalAccessory')")
                          .slide-border-bottom(:style="getBackgroundPictureWithContain(item.slideMosquitoNetGlobalAccessoryEntry, 'globalAccessory')")
                            .slide-border-bottom-inside(:style="getBackgroundPictureWithContain(item.slideMosquitoNetGlobalAccessoryEntry, 'globalAccessory')")
                          .bar(v-if="item.isBar" :style="getBackgroundPictureWithContain(item.slideMosquitoNetGlobalAccessoryEntry, 'globalAccessory')")

              br
              .column
                .columns.is-multiline.is-mobile
                  .column.is-12-desktop.is-12-tablet
                    .columns
                      .column
                        app-view-card(:title="$t('version')" class="has-margin-bottom")
                          figure
                            .level
                              .level-item
                                img.has-pointer(
                                  v-if="image(item.slideMosquitoNet, 'mechanismPicture')"
                                  :src="image(item.slideMosquitoNet, 'mechanismPicture')"
                                  width="76"
                                  @click="openImage(image(item.slideMosquitoNet, 'mechanismPicture'))"
                                )
                  .column.is-12-desktop.is-12-tablet
                    .columns
                      .column
                        app-view-card(:title="$t('survey')" class="has-margin-bottom")
                          figure
                            .level
                              .level-item
                                img.has-pointer(
                                  v-if="image(item.slideMosquitoNet, 'surveyPicture')"
                                  :src="image(item.slideMosquitoNet, 'surveyPicture')"
                                  width="100"
                                  @click="openImage(image(item.slideMosquitoNet, 'surveyPicture'), item.slideMosquitoNet['surveyDescription'+getLocale])"
                                )
                  .column.is-12-desktop.is-12-tablet
                    .columns
                      .column
                        app-view-card(:title="$t('files')")
                          a(
                            v-if="item.slideMosquitoNet && item.slideMosquitoNet.measurementInstruction"
                            class="button is-primary"
                            :href="item.slideMosquitoNet.measurementInstruction.contentUrl"
                            target="_blank"
                            download
                          ) {{ $t('measurementInstruction') }}
    br
    b-modal(v-model="imageModal")
      .columns(style="width:99%").has-text-centered
        img(:src="imageForModal")
        .columns(v-if="centeredTextForModal")
          .column
          .column.is-half.has-text-centered
            .has-background-white.has-text-black(v-html="centeredTextForModal")
          .column
</template>

<script>
import http from "../../../../http";
import {mapActions, mapGetters, mapMutations} from "vuex";
import notify from "../../../../helpers/notify";
import simpleClone from "../../../../helpers/simpleClone";
import ResourceSelect from "@/modules/common/components/ResourceSelect";
import prepareItemToSent from "@/helpers/prepareItemToSent";
import objectToCollection from "@/helpers/objectToCollection";
import priceFormatter from "../../../../helpers/priceFormatter";
import AutomationFormModal from "../../../automation/components/AutomationFormModal";

function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
        args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default {
  components: {ResourceSelect, AutomationFormModal},
  data() {
    return {
      globalError: null,
      errors: [],
      submitting: false,
      showWidthError: false,
      priceLoading: false,
      imageModal: false,
      imageForModal: "",
      centeredTextForModal: null,
      hoverImage: null,
      backgroundColor: "#E6E6E6",
      windowFrame: null,
      globalOrderRemoteControlIsPosting: false,
      showHelpForOrderDimensions: false,
      showHelpForBarHeightFromGround: false,
      isCalculated: false,
      automationFormModal: false,
    };
  },
  methods: {
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    ...mapActions("slideMosquitoNets", [
      "setMessage",
      "setIsMessageBorderAnimating",
      "toggleShowForm",
      "saveItem",
      "cancelEdit",
      "saveItemAndClose",
      "updateOrder",
      "updateClearOrder",
      "clearOrder",
    ]),
    ...mapMutations("slideMosquitoNets", ["setItem"]),
    setMessageFromAccessory(message) {
      if (message && message.length > 0) {
        this.setMessage(message);
        this.setIsMessageBorderAnimating(true);
        setTimeout(() => {
          this.setIsMessageBorderAnimating(false);
        }, 1000);
      }
    },
    //nadpisuje eventy keyup i keydown, preventDefault jest defaultowy, oryginalnie zwraca fora, który jest tu wykonywany
    //emit jest customowy
    //up
    // fabricColorHandlers: (map, vm) => ({
    //   ...map,
    //   38: e => {
    //     e.preventDefault();
    //
    //     for (let i = vm.typeAheadPointer - 1; i >= 0; i--) {
    //       if (vm.selectable(vm.filteredOptions[i])) {
    //         vm.typeAheadPointer = i;
    //         break;
    //       }
    //     }
    //     vm.$emit("input", vm.filteredOptions[vm.typeAheadPointer]);
    //   },
    //   //down
    //   40: e => {
    //     e.preventDefault();
    //     for (
    //         let i = vm.typeAheadPointer + 1;
    //         i < vm.filteredOptions.length;
    //         i++
    //     ) {
    //       if (vm.selectable(vm.filteredOptions[i])) {
    //         vm.typeAheadPointer = i;
    //         break;
    //       }
    //     }
    //     vm.$emit("input", vm.filteredOptions[vm.typeAheadPointer]);
    //   }
    // }),
    barHeightFromGroundHelp() {
      if (!this.item.isBar) return;
      return this.$t("bar_height_from_ground_help", {
        lengthUnit: this.lengthUnit
      });
    },
    getPictureFromNestedElement(src, nestedElement) {
      const item = src && src[nestedElement];
      const picture = item && item["picture"];

      return picture || null;
    },
    //zwraca prosty url
    getPictureUrl(src, nestedField = null) {
      let picture = null;

      if (nestedField) {
        picture = this.getPictureFromNestedElement(src, nestedField);
      } else {
        picture = src && src["picture"];
      }

      if (picture) {
        return "url(" + picture.contentUrl + ")";
      }

      return null;
    },
    //zwraca obiekt stylu z tłem i background-size: contain
    getBackgroundPictureWithContain(src, nestedField = null) {
      let picture = null;

      if (nestedField) {
        picture = this.getPictureFromNestedElement(src, nestedField);
      } else {
        picture = src && src["thumbnail"];
      }

      if (picture) {
        return {
          borderImage: "url(" + picture.contentUrl + ") 0 0 0 0 stretch stretch",
          background: "url(" + picture.contentUrl + ") 0% 0% / contain"
        };
      }
      return null;
    },
    getBackgroundPictureWithCover(src, nestedField = null) {
      let picture = null;

      if (nestedField) {
        picture = this.getPictureFromNestedElement(src, nestedField);
      } else {
        picture = src && src["thumbnail"];
      }

      if (picture) {
        return {
          borderImage: "url(" + picture.contentUrl + ") 0 0 0 0 stretch stretch",
          background: "url(" + picture.contentUrl + ") 0% 0% / cover",
        };
      }
      return null;
    },
    //zwraca obiekt stylu z tłem
    getBackgroundPicture(src, nestedField = null) {
      let picture = null;

      if (nestedField) {
        picture = this.getPictureFromNestedElement(src, nestedField);
      } else {
        picture = src && src["picture"];
      }

      if (picture) {
        return {
          background: "url(" + picture.contentUrl + ")"
        };
      }

      return null;
    },
    openImage(image, text = null) {
      this.imageForModal = image;
      if (text) {
        this.centeredTextForModal = text;
      } else {
        this.centeredTextForModal = null;
      }
      this.imageModal = true;
    },
    asArray(object, field) {
      return (object && object[field]) || [];
    },
    onFabricShadeFabricShadeAdditonalEntriesChanged() {
      this.calculatePrice();
    },
    onGlobalDriveChanged(shouldCalculateAndChangeMessage = true) {
      if (shouldCalculateAndChangeMessage) {
        this.setMessageFromAccessory(this.item.globalDrive.message);
        this.calculatePrice();
      }
    },
    onQuantityChange() {
      const side = this.item.driveSide;
      if (side !== "P" || side !== "L" || side !== "L+P") {
        this.item.driveSide = "L";
      }
      this.calculatePrice();
    },
    onSlideMosquitoNetChanged() {
      this.setMessageFromAccessory(this.item.slideMosquitoNet.message);
      if (this.itemSlideMosquitoNetGlobalAccessoryEntries.find(entry => entry["@id"] === this.item.slideMosquitoNetGlobalAccessoryEntry["@id"] &&
          entry.globalAccessory["@id"] === this.item.slideMosquitoNetGlobalAccessoryEntry.globalAccessory["@id"]) === undefined) {
        this.item.slideMosquitoNetGlobalAccessoryEntry = this.itemSlideMosquitoNetGlobalAccessoryEntries[0];
      }
      if (this.itemSlideMosquitoNetGlobalNetEntries.find(entry => entry["@id"] === this.item.slideMosquitoNetGlobalNetEntry["@id"]
          && entry.globalNet["@id"] === this.item.slideMosquitoNetGlobalNetEntry.globalNet["@id"]) === undefined) {
        this.item.slideMosquitoNetGlobalNetEntry = this.itemSlideMosquitoNetGlobalNetEntries[0];
      }


      this.calculatePrice();
    },

    isWidthValid() {
      this.removeError("width");

      if (this.item.width > this.maxWidth) {
        this.addError(
            "width",
            this.$t("max_width_error", {
              width: this.formatLength(this.maxWidth),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      if (this.item.width < this.minWidth) {
        this.addError(
            "width",
            this.$t("min_width_error", {
              width: this.formatLength(this.minWidth),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      return true;
    },

    onSlideMosquitoNetGlobalAccessoryEntryChanged() {
      this.calculatePrice();
    },
    onSlideMosquitoNetAdditionalChanged() {
      this.calculatePrice();
    },
    onSlideMosquitoNetFrameChanged() {
      this.calculatePrice();
    },
    onSlideMosquitoNetBottomRailChanged() {
      this.calculatePrice();
    },
    onWidthChange() {
      this.showHelpForOrderDimensions = false;
      this.isWidthValid()
    },
    onBarHeightFromGroundChange() {
      this.showHelpForBarHeightFromGround = false;
      this.isBarHeightFromGroundValid();
    },
    isBarHeightFromGroundValid() {
      this.removeError("barHeightFromGround");

      if (this.barHeightFromGroundDisabled) {
        this.item.barHeightFromGround = 0
        return true;
      }

      if (this.item.isBar && this.item.barHeightFromGround <= 0) {
        this.addError(
            "barHeightFromGround",
            this.$t("min_bar_height_from_ground_error", {
              height: 0,
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      if (this.item.barHeightFromGround > this.item.height) {
        this.addError(
            "barHeightFromGround",
            this.$t("max_bar_height_from_ground_error", {
              height: this.formatLength(this.item.height),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }
    },
    onIsBarChanged() {
      this.showHelpForBarHeightFromGround = false;
      this.isBarHeightFromGroundValid();
    },

    isHeightValid() {
      this.removeError("height");

      if (this.item.height > this.maxHeight) {
        this.addError(
            "height",
            this.$t("max_height_error", {
              height: this.formatLength(this.maxHeight),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      if (this.item.height < this.minHeight) {
        this.addError(
            "height",
            this.$t("min_height_error", {
              height: this.formatLength(this.minHeight),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      return true;
    },
    onHeightChange() {
      this.showHelpForOrderDimensions = false;
      this.isHeightValid();
    },
    setWindowSlide(newWindowSlide) {
      this.windowFrame = newWindowSlide;
    },
    resetWindowSlide() {
      this.windowFrame = null;
    },
    calculatePrice: debounce(async function () {
      if (
          this.item.width >= this.minWidth &&
          this.item.height >= this.minHeight
      ) {
        this.priceLoading = true;
        let item = simpleClone(this.item);
        item = prepareItemToSent(item);
        delete item["@id"];
        delete item.id;
        await http
            .post(`/calculate/slide_mosquito_net_order_item`, item)
            .then(({data}) => {
              this.item.netPurchaseValue = data.netPurchaseValue;
              this.item.netSalesValue = data.netSalesValue;
              this.isCalculated = true;
            })
            .catch(error => {
              this.isCalculated = false;
              notify(error.response.data["hydra:description"], "danger");
            });
        this.priceLoading = false;
      }
    }, 400),
    addError(field, message) {
      let existsError = this.errors.find(
          ({propertyPath}) => propertyPath === field
      );
      if (existsError) {
        existsError.message = message;
      } else {
        this.errors = this.errors.concat({propertyPath: field, message});
      }
    },
    removeError(field) {
      this.errors = this.errors.filter(
          ({propertyPath}) => field !== propertyPath
      );
    },
    handleItemWidthChange() {
      this.calculatePrice();
    },
    handleItemHeightChange() {
      this.calculatePrice();
    },
    handleItemGlassHeightChange() {
      this.calculatePrice();
    },
    handleItemGlassWidthChange() {
      this.calculatePrice();
    },

    clearValuation() {
      return this.$buefy.dialog.confirm({
        message: this.$t("remove_valuation_confirm"),
        onConfirm: () => {
          this.clearOrder();
        }
      });
    },
  },
  /**
   * Computed properties
   */
  computed: {
    orderHasAtLeastOneItem() {
      return this.order.slideMosquitoNetOrderItems.length > 0;
    },
    companyInstallationValuesBySelectedFabricShade() {
      if (!this.item.slideMosquitoNet) {
        return [];
      }

      const installationArray = objectToCollection(
          this.companyInstallationValues
      );

      return installationArray.filter(
          civ =>
              civ.slideMosquitoNets &&
              civ.slideMosquitoNets.findIndex(
                  fs => fs === this.item.slideMosquitoNet["@id"]
              ) > -1
      );
    },

    getLocale() {
      const local = this.$i18n.locale.toUpperCase();
      if (this.locales.find(locale => local === locale)) {
        return local;
      }
      return "EN";
    },
    windowSlideBackgroundImage() {
      if (this.windowFrame && this.windowFrame.picture) {
        return {
          backgroundImage: "url(" + this.windowFrame.picture.contentUrl + ")"
        };
      }
      return {
        backgroundColor: "white"
      };
    },
    loading() {
      return this.$store.getters["common/loading"];
    },
    itemDataTitle() {
      if (this.isEdit) {
        return "item_edit_data_title";
      }
      return "item_data_title";
    },
    addButtonLabel() {
      if (this.isEdit) {
        return "save";
      }
      return "add";
    },
    cantSave() {
      if (!this.isCalculated) {
        return true;
      } else if (this.item.width === 0 || this.item.height === 0) {
        return true;
      } else if (
          this.item.width > this.maxWidth ||
          this.item.height > this.maxHeight
      ) {
        return true;
      } else if (this.errors.length > 0) {
        return true
      }
      return false;
    },
    getHideImage() {
      return this.hideImage
    },
    ...mapGetters("slideMosquitoNets", [
      "order",
      "showForm",
      "image",
      "slideMosquitoNets",
      "slideMosquitoNetFrames",
      "slideMosquitoNetBottomRails",
      "itemSlideMosquitoNet",
      "ifCanBeSentInParts",
      "ifHasCrossbar",
      "itemSlideMosquitoNetGlobalAccessoryEntries",
      "itemSlideMosquitoNetGlobalNetEntries",
      "itemSlideMosquitoNetFrame",
      "itemSlideMosquitoNetBottomRail",
      "item",
      "isEdit",
      "message",
      "isMessageBorderAnimating",
      "changeStatusesForEditItem",
      "barHeightFromGroundDisabled"
    ]),
    ...mapGetters(["locales", "hideImage", "hideWholeSalePrice"]),
    ...mapGetters("company", ["lengthUnit", "companyInstallationValues"]),
    ...mapGetters("common", ["formatLength", "formatPrice", "yesNoOptions"]),
    pictureTitle() {
      if (this.item.slideMosquitoNet) {
        return this.$t("picture_review", {
          slideMosquitoNetName: this.item.slideMosquitoNet.name
        });
      }
      return " ";
    },
    widthHelp() {
      const showMax = this.maxWidth !== Number.MAX_SAFE_INTEGER;

      let message = `min: ${this.formatLength(this.minWidth)} ${
          this.lengthUnit
      }`;

      message += showMax
          ? `, max: ${this.formatLength(this.maxWidth)} ${this.lengthUnit}`
          : ``;

      return message;
    },
    heightHelp() {
      const showMax = this.maxHeight !== Number.MAX_SAFE_INTEGER;

      let message = `min: ${this.formatLength(this.minHeight)} ${
          this.lengthUnit
      }`;

      message += showMax
          ? `, max: ${this.formatLength(this.maxHeight)} ${this.lengthUnit}`
          : ``;

      return message;
    },
    minWidth() {
      return this.item.slideMosquitoNet && this.item.slideMosquitoNet.minWidth;
    },
    maxWidth() {
      const maxWidth = this.item.slideMosquitoNet && this.item.slideMosquitoNet.maxWidth;
      if (maxWidth) {
        return maxWidth;
      }

      return Number.MAX_SAFE_INTEGER;
    },
    maxHeight() {
      return this.item.slideMosquitoNet && this.item.slideMosquitoNet.maxHeight;
    },
    minHeight() {
      return this.item.slideMosquitoNet && this.item.slideMosquitoNet.minHeight;
    },
  },
  /**
   * Watchers
   */
  watch: {
    "item.width": "handleItemWidthChange",
    "item.height": "handleItemHeightChange",
    companyInstallationValuesBySelectedFabricShade(values) {
      if (values.length > 0) {
        this.item.companyInstallationValue = values[0];
      }
    },
  }
};
</script>
