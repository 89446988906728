<template lang="pug">
  .hover-image-preview(v-if="image")
      img(:src="image" class="thumbnail-image higher-thumbnail" @mouseover="hoverImage = image" @mouseout="hoverImage = null" @click="$emit('open', image)")
      //img(:src="image" width="800" class="thumbnail-image-large is-1by1" v-if="hoverImage === image")
</template>
<script>

export default {
  props: {
    image: String
  },
  data() {
    return {
      hoverImage: null
    };
  }
};
</script>

<style>
.higher-thumbnail{
  max-height: 80px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
.hover-image-preview{
  width:100%;
}
.higher-thumbnail {
  top:-50px;
  left:-35px;
  display:block;
  z-index:999;
  cursor: pointer;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
}

.higher-thumbnail:hover {
  transform: scale(3);

}
</style>

