export const FABRIC_SHADE = "fabric_shade";
export const FABRIC_SHADE_DAY_NIGHT = "fabric_shade_day_night";
export const EXTERNAL_SHUTTER = "external_shutter";

export const PLEAT = "pleat";

export const DETAIL = "detail";
export const FRAME_MOSQUITO_NET = "frame_mosquito_net"
export const ROLL_MOSQUITO_NET = "roll_mosquito_net"
export const PLEAT_MOSQUITO_NET = "pleat_mosquito_net"
export const DOOR_MOSQUITO_NET = "door_mosquito_net"
export const SLIDE_MOSQUITO_NET = "slide_mosquito_net"
