<template lang="pug">
  tr(:class="classes")
    // liczba porządkowa
    td
      div
        .th-in {{ (index + 1) }}
    //miejsce montazu/krotki opis
    td {{ item.shortDescription }}
    // nazwa wersji
    td {{ property(item.doorMosquitoNet, "name") }}
    // czy złożona
    td {{ formatBoolean(item.isAssembly) }}
    // kolor ramy
    td {{ property(item.doorMosquitoNetGlobalAccessoryEntry, "name", "globalAccessory") }}
    // siatka
    td {{ property(item.doorMosquitoNetGlobalNetEntry, "name", "globalNet") }}
    // konstruckja ramy
    td {{ property(item.doorMosquitoNetFrame, "name") }}
    // szyna dolna
    td {{ property(item.doorMosquitoNetBottomRail, "name") }}
    // szerokość
    td {{ formatLength(item.width) }}
    // wysokość
    td {{ formatLength(item.height) }}
    // poprzeczka
    td {{ item.isBar ? $t("yes") : '-' }}
    // ilość szt.
    td {{ item.quantity }}
    // Dodatki
    td
      p(v-if="!item.doorMosquitoNetAdditionalEntries.length") {{$t('empty')}}
      p(v-for="(itemAdditional) in item.doorMosquitoNetAdditionalEntries") {{ property(itemAdditional, "name", "doorMosquitoNetAdditional") }}
    //// cena zakupu
    td.has-text-right(v-if="!hideWholeSalePrice") {{ formatPriceValue(formatPrice(item.netPurchaseValue)) }}
    // cena sprzedaży
    td.has-text-right {{ formatPriceValue(formatPrice(item.netSalesValue)) }}
    td
      .field.has-addons
        p.control
          b-button(
            @click.prevent="editItem({index, item})"
            type="is-info"
            size="is-small"
            icon-left="pencil"
          )
        p.control
          b-button(
            @click.prevent="remove"
            type="is-danger"
            icon-left="delete"
            size="is-small"
          )
</template>
<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import priceFormatter from "../../../../helpers/priceFormatter";

export default {
  props: {
    index: Number,
    item: Object
  },
  methods: {
    ...mapActions("doorMosquitoNets", ["removeItem"]),
    ...mapMutations("doorMosquitoNets", ["editItem"]),
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    remove() {
      return this.$buefy.dialog.confirm({
        message: this.$t("remove_position_confirm"),
        onConfirm: () => {
          this.removeItem(this.index);
        }
      });
    },
    property(object, field, extraField = null) {
      if (extraField) {
        return object && object[extraField][field];
      }
      return object && object[field];
    }
  },
  computed: {
    classes() {
      return {
        "is-editing": this.editIndex === this.index
      };
    },
    ...mapGetters(["hideWholeSalePrice"]),
    ...mapGetters("doorMosquitoNets", ["editIndex"]),
    ...mapGetters("common", [
      "formatLength",
      "formatNumber",
      "formatBoolean",
      "formatPrice"
    ])
  }
};
</script>
