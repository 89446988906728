<template lang="pug">
    div
        nav.breadcrumb
            ul
                li.is-active
                    a {{ $t('door_mosquito_net_order_title') }}
                    h2( v-if='order.valuationDate' style="font-weight:700") {{' '+order.name}}

        door-mosquito-net-order-items
        .columns.is-multiline.is-horizontal
          .column.is-one-quarters
            summary-prices-in-order
        transition(name="door_mosquito-net-form")
            div(v-if="showForm")

                section.has-margin-top-small.has-margin-bottom-small
                    door-mosquito-net-order-item-form(:order-is-editing="!isNaN(this.id) && this.id !== null")
        .columns.is-multiline
            .column.is-half-tablet.is-one-third-desktop(v-if="buyType === 'retail'")
                //
                // customer panel
                //
                app-view-card(:title="$t('customer_panel_title')")
                    form
                        b-field
                            b-autocomplete(
                                v-model = "searchCustomerInput"
                                :data="customers"
                                placeholder="Wybierz klienta"
                                field="firstName"
                                :loading="isFetching"
                                @typing="getAsyncData"
                                @select="option => {onCustomerChanged(option);}"
                            )
                                template(slot-scope="props") {{ props.option.firstName }} {{ props.option.lastName }} {{ props.option.companyName || "" }} {{ props.option.taxId || "" }} {{ props.option.zipCode || "" }} {{ props.option.city || "" }}

                        app-form-field(:label="$t('customer_type')" field="type")
                            div
                                b-radio(v-model="order.customer.type" native-value="person") {{ $t('customer_types.person') }}
                                b-radio(v-model="order.customer.type" native-value="company") {{ $t('customer_types.company') }}
                            .columns(v-show="order.customer.type === 'company'")
                                .column
                                    app-form-field(:label="$t('tax_id')" field="taxId" :required="order.customer.type === 'company'" :errors="errors" :translatable="true")
                                      b-field
                                        b-input(v-model="order.customer.taxId" @keydown.enter.prevent.native="fetchGus" expanded)
                                        .control
                                          b-button(type="is-primary" @click.prevent="fetchGus" :loading="gusFetching") GUS
                                .column
                                    app-form-field(:label="$t('company_name')" field="companyName" :required="order.customer.type === 'company'" :errors="errors" :translatable="true")
                                        b-input(v-model="order.customer.companyName")
                            .columns
                                .column
                                    app-form-field(:label="$t('first_name')" field="firstName")
                                        b-input(v-model="order.customer.firstName")
                                .column
                                    app-form-field(:label="$t('last_name')" field="lastName" :required="order.customer.type === 'person'" :errors="errors" :translatable="true")
                                        b-input(v-model="order.customer.lastName")
                            .columns
                                .column
                                    app-form-field(:label="$t('city')" field="city" :required="true" :errors="errors" :translatable="true")
                                        b-input(v-model="order.customer.city")
                                .column
                                    app-form-field(:label="$t('zip_code')" field="zipCode")
                                        b-input(v-model="order.customer.zipCode")
                            .columns
                                .column
                                    app-form-field(:label="$t('street')" field="streetAndNumber")
                                        b-input(v-model="order.customer.streetAndNumber")
                                .column
                                    app-form-field(:label="$t('phone')" field="phone")
                                        b-input(v-model="order.customer.phone")
                            .columns
                                .column
                                    app-form-field(:label="$t('email')" field="email")
                                        b-input(v-model="order.customer.email")
                            .columns
                                .column(v-if="order.customer.id")
                                    b-field
                                        b-button(type="is-primary" @click="confirmCustomerUpdate") {{ $t('update_customer') }}
                                .column
                                    b-field
                                        b-button(type="is-primary" @click="confirmCustomerSave") {{ $t('save_new_customer') }}
                                .column
                                    b-field
                                        b-button(@click="resetCustomerData") {{ $t('reset_fields') }}
            .column.is-half-tablet.is-one-third-desktop(v-if="buyType === 'retail'")
              //
              // order summary panel
              //
              app-view-card(:title="$t('set_of_order_value')")
                table.table.is-fullwidth
                  tbody
                    tr
                      th(colspan=2) {{ $t('net_value') }}
                      td {{ formatPriceValue(formatPrice(order.netSalesValue)) }}
                    tr
                      th(colspan=2) {{ $t('net_value_automation') }}
                      td {{ formatPriceValue(formatPrice(order.netSalesAutomationValue)) }}
                    tr
                      th {{ $t('discount') }}
                      td
                        app-form-field(field="percentDiscount" :errors="errors" :translatable="true")
                          app-form-decimal(v-model="order.percentDiscount" @input="updateOrder" style="width: 75%")
                          span.button.is-static(style="width: 20%; height:21px!important") %
                      td {{ formatPriceValue(formatPrice(order.discountValue)) }}
                    tr
                      th {{ $t('discount_automation') }}
                      td
                        app-form-field(field="percentDiscountAutomation" :errors="errors" :translatable="true")
                          app-form-decimal(v-model="order.percentDiscountAutomation" @input="updateOrder" style="width: 75%")
                          span.button.is-static(style="width: 20%; height:21px!important ") %
                      td {{ formatPriceValue(formatPrice(order.discountAutomationValue)) }}
                    tr
                      th(colspan=2) {{ $t('net_value_after_discount') }}
                      td {{ formatPriceValue(formatPrice(order.netSalesValueAfterDiscount)) }}
                    tr
                      th(colspan=2) {{ $t('net_value_automation_after_discount') }}
                      td {{ formatPriceValue(formatPrice(order.netSalesAutomationValueAfterDiscount)) }}
                    tr
                      th {{ $t('installation') }}
                      td
                        b-field
                          b-checkbox(v-model="order.withInstallation" @input="updateOrder") {{ order.withInstallation ? $t('yes') : $t('no') }}
                      td  {{ formatPriceValue(formatPrice(order.installationValue)) }}
                    tr
                      th {{ $t('order.fitter_travel_cost') }}
                      td
                        b-select(v-model="order.fitterTravelCost" @input="updateOrder" expanded)
                          option(:value="null") {{ $t('empty') }}
                          option(v-for="(option) in fitterTravelCosts" :value="option['@id']") {{ option.name }}
                      td {{ formatPriceValue(formatPrice(order.fitterTravelCostValue)) }}
                    tr
                      th(colspan=2) {{ $t('order.total_net_value') }}
                      td {{ formatPriceValue(formatPrice(order.totalSalesNetValue)) }}
                    tr
                      th VAT
                      td
                        b-field
                          b-select(v-model="order.companyVatRate" @input="updateOrder" expanded)
                            option(v-for="(option, id) in companyVatRates" :value="option['@id']") {{ option.name }}
                      td {{ formatPriceValue(formatPrice(order.salesVatRateValue)) }}
                    tr
                      th(colspan=2) {{ $t('gross_value') }}
                      td {{ formatPriceValue(formatPrice(order.salesGrossValue)) }}
                    tr
                      th(colspan=2) {{ $t('advance_payment_of') }}
                      td
                        app-form-decimal(v-model="order.advanceValue" @input="updateOrder")
                    tr
                      th(colspan=2) {{ $t('left_to_pay') }}
                      th {{ formatPriceValue(formatPrice(order.leftToPayValue)) }}
            .column.is-half-tablet.is-one-third-desktop
                //
                // payment and delivery methods
                //
                app-view-card(:title="`${$t('summary')}`")
                    table.table.is-fullwidth
                        tbody
                            tr
                                th {{ $t('payment_method') }}
                                td
                                    b-field
                                        b-select(v-model="order.paymentMethod" @input="updateOrder" expanded)
                                            option(v-for="(option, id) in paymentMethods" :value="option") {{ option.name }}
                            tr
                                th {{ $t('order_number') }}

                                td
                                    b-field
                                        b-input(:value="orderNumber" :disabled="true" expanded)
                            tr
                                th {{ $t('delivery_method') }}
                                td
                                    b-field
                                        b-select(v-model="order.deliveryMethod" @input="updateOrder" expanded)
                                            option(v-for="(option, id) in deliveryMethods" :value="option") {{ option.name }}
                            tr
                                th(colspan=2)
                                    b-field(:label="$t('package_description')")
                                      b-input(v-model="order.packageNote" @change.native="updateOrder")
                                    b-field(:label="$t('will_added_on_placing_order_to_notes')")
                                      b-input(v-model="extraPackageNoteLocalObject")
                            tr
                                th(colspan=2)
                                    b-field(:label="`${$t('notes')} ${Math.round(order.netPurchaseValue / 100)} / ${new Date().getFullYear()}`")
                                        b-input(v-model="order.notes" type="textarea" @change.native="updateOrder")
            .column
                //
                // actions
                //
                app-view-card(:title="$t('actions')")
                    .columns.is-desktop
                        .column.is-2
                            .order-name-input-min-width
                                b-input(v-model="order.name")
                        .column
                            .buttons
                                //saving order as valuation to make offer in future
                                b-button(type="is-primary" @click="confirmValuation") {{ $t('save_valuation') }}
                                //saving order as complete
                                b-button(type="is-info" @click="confirmPlace") {{ $t('complete_order') }}
                                //toggle show form
                                b-button(type="is-warning" @click="toggleShowForm") {{ $t(`${showForm?'hide':'show'}_form`) }}
                        .column(v-if="order.doorMosquitoNetOrderItems.length > 0")
                            .buttons
                              //print full valuation
                              b-button(type="is-primary" @click="printFullValuation") {{ $t('print_full_valuation') }}
                              //simplified valuation print
                              b-button(type="is-info" @click="printSimplifiedValuation") {{ $t('print_simplified_valuation') }}
        order-print(:active="active" @close="active=false" :order-id="order.id" :print-type="valuationPrintType" :price-type="'sales'")
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import http from "@/http";
import generateProperties from "@/helpers/generateProperties";
import notify from "@/helpers/notify";
import simpleClone from "@/helpers/simpleClone";
import OrderPrint from "@/modules/common/components/OrderPrint";
import priceFormatter from "../../../helpers/priceFormatter";
import SummaryPricesInOrder from "./components/SummaryPricesInOrder";
import DoorMosquitoNetOrderItems from "./components/DoorMosquitoNetOrderItems";
import DoorMosquitoNetOrderItemForm from "./components/DoorMosquitoNetOrderItemForm.vue";

function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    const context = this,
      args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default {
  props: ["id","copyId"],
  data() {
    return {
      active: false,
      valuationPrintType: "",
      customers: [],
      selectedCustomer: null,
      isFetching: false,
      searchCustomerInput: ""
    };
  },
  components: {
    DoorMosquitoNetOrderItemForm,
    DoorMosquitoNetOrderItems,
    OrderPrint,
    SummaryPricesInOrder
  },
  computed: {
    ...mapGetters("company", [
      "fitterTravelCosts",
      "vatRate",
      "paymentMethods",
      "deliveryMethods",
      "companyVatRates",
      "buyType"
    ]),
    ...mapGetters("common", ["formatLength", "formatPrice"]),
    ...mapGetters("doorMosquitoNets", [
      "extraPackageNote",
      "showForm",
      "order",
      "gusFetching",
      "errors",
      "orderNumber"
    ]),
    isEditing() {
      if (this.id === null) {
        return false;
      }
      return !isNaN(this.id);
    },
    orderYear() {
      return (
        new Date(this.order.createdAt).getFullYear() || new Date().getFullYear()
      );
    },
    packageNotePreview() {
      const taxId = this.order.customer.taxId;
      if (this.order.customer.type === "person") {
        return `${this.order.customer.lastName || ""}`;
      }
      return `${this.order.customer.companyName || ""} ${taxId?"(":""}${taxId || ""}${taxId?")":""}`;
    },
    extraPackageNoteLocalObject: {
      get() {
        return this.extraPackageNote;
      },
      set(value) {
        this.setExtraPackageNote(value);
      }
    }
  },
  methods: {
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    printFullValuation() {
      this.active = true;
      this.valuationPrintType = "full";
    },
    printSimplifiedValuation() {
      this.active = true;
      this.valuationPrintType = "simplified";
    },
    confirmCustomerSave() {
      this.$buefy.dialog.confirm({
        message: this.$t("confirm_customer_save"),
        onConfirm: () => {
          this.saveCustomer();
        }
      });
    },
    confirmCustomerUpdate() {
      this.$buefy.dialog.confirm({
        message: this.$t("confirm_customer_update"),
        onConfirm: () => {
          this.saveCustomer("update");
        }
      });
    },
    confirmValuation() {
      this.$buefy.dialog.confirm({
        message: this.$t("confirm_valuate_order"),
        confirmText: this.$t("yes"),
        cancelText: this.$t("no"),
        onConfirm: () => {
          this.valuateOrder(this.isEditing);
          this.searchCustomerInput = "";
        }
      });
    },
    confirmPlace() {
      this.$buefy.dialog.confirm({
        message: this.$t("confirm_place_order"),
        confirmText: this.$t("yes"),
        cancelText: this.$t("no"),
        onConfirm: () => {
          this.placeOrder(this.isEditing);
          this.searchCustomerInput = "";
        }
      });
    },
    ...mapActions("doorMosquitoNets", [
      "toggleShowForm",
      "updateOrder",
      "saveCustomer",
      "placeOrder",
      "valuateOrder",
      "getOrderForCopy",
      "getOrder",
      "fetchGus",
      "resetCustomer",
      "deleteError",
      "setExtraPackageNote"
    ]),
    setExtraPackageNoteData() {
      const customer = this.order.customer;
      if (customer.lastName) {
        this.setExtraPackageNote(customer.lastName);
      }
      else if(customer.companyName){
        this.setExtraPackageNote(customer.companyName);
      }
    },
    onCustomerChanged(option) {
      if (option) {
        this.order.customer = simpleClone(option);
        this.updateOrder();
      }
    },
    resetCustomerData() {
      this.searchCustomerInput = "";
      this.resetCustomer();
    },
    getAsyncData: debounce(function(name) {
      if (!name.length) {
        this.customers = [];
        return;
      }
      this.isFetching = true;
      http
        .get(
          `/customers?` +
            generateProperties(
              [],
              [
                "id",
                "firstName",
                "lastName",
                "zipCode",
                "city",
                "streetAndNumber",
                "taxId",
                "companyName",
                "phone",
                "email",
                "type"
              ]
            ) +
            `&search=${name}`
        )
        .then(({ data }) => {
          this.customers = [];
          this.customers = data["hydra:member"];
        })
        .catch(error => {
          this.customers = [];
          throw error;
        })
        .finally(() => {
          this.isFetching = false;
        });
    }, 500)
  },
  async created() {
    if (
      this.$route.name.includes("edit") &&
      this.id !== null &&
      !isNaN(this.id)
    ) {
      http
        .get(
          `/orders/${this.id}?status=valuation&category=door_mosquito_net&properties[]=id`
        )
        .then(response => {
          if (response.data && response.data.id) {
            this.getOrder(this.id);
          } else {
            notify("Nie odnaleziono wyceny", "danger");
            this.$router.push({ name: "valuations_list" });
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            notify("Nie odnaleziono wyceny", "danger");
            this.$router.push({ name: "valuations_list" });
          }
        });
    } else {
      await this.getOrder();
      if(this.copyId) await this.getOrderForCopy( this.copyId);
    }
  },
  watch: {
    "order.customer.type": function() {
      this.deleteError("taxId");
      this.deleteError("lastName");
      this.deleteError("companyName");
      this.deleteError("city");
      this.setExtraPackageNoteData();
    },
    "order.customer.lastName": function() {
      this.setExtraPackageNoteData();
    },
    "order.customer.companyName": function() {
      this.setExtraPackageNoteData();
    }
  }
};
</script>
