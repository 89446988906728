import router from "./../../router";
import CreateOrder from "./views/CreateOrder";
import store from "../../store";
import doorMosquitoNet from "./store";

store.registerModule("doorMosquitoNets", doorMosquitoNet);
router.addRoutes([
  {
    path: "/door_mosquito_net/create_order/:copyId?",
    name: "door_mosquito_net_create_order",
    component: CreateOrder,
    props: true
  }
]);

router.addRoutes([
  {
    path: "/door_mosquito_net/edit_order/:id",
    name: "door_mosquito_net_edit_order",
    component: CreateOrder,
    props: true
  }
]);
